import { Autocomplete, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import React, { useState } from 'react'

interface Props { users: DocumentData[], eventId: string, onClose: () => void }

export default function NewNotificationDialogComponent(props: Props) {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');



  const handleSendClick = async () => {
    if (title.length == 0 || body.length == 0 || props.users.length == 0) return alert('Izpolnite vsa polja, in izberite vsaj enega uporabnika')
    const data = new URLSearchParams({
      users: props.users.map((user) => user.id).toString(),
      title: title,
      message: body
    });

    const res = await fetch(
      "https://us-central1-as-medic.cloudfunctions.net/sendCustomNotificationToSelectedUsers?" +
      data
    );

    props.onClose();
  }

  return (
    <div>
      <DialogTitle>Obvestilo</DialogTitle>

      <DialogContent>
        <h2 className="text-lg mt-5 font-bold">Vsebina</h2>
        <div className="py-4 flex flex-col gap-4">
          <TextField
            id="outlined-multiline-static"
            label="Naslov"
            fullWidth
            rows={4}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            id="outlined-multiline-static"
            label="Vsebina"
            multiline
            fullWidth
            rows={4}
            style={{ width: '400px' }}
            onChange={(e) => setBody(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSendClick}>Pošlji</Button>
      </DialogActions>
    </div>
  );
}
