import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { addDoc, collection, deleteDoc, doc, getFirestore, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddEditComponent from "./AddEditComponent";
import { deleteUser, getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(firebaseClient);

export default function VozilaPage() {
	// auth check
	const navigate = useNavigate();
	const [user, userLoading, userError] = useAuthState(auth);
	if (!user) navigate("/prijava");

	const [vehicles, loading, vehiclesError] = useCollection(collection(getFirestore(firebaseClient), "vehicle"));
	const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
	const [editingObject, setEditingObject] = useState<Vozilo | null>(null);

	const rows: Vozilo[] = useMemo(() => {
		if (!vehicles || vehiclesError) return [];

		return vehicles?.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				brand: data.brand,
				licence_plate: data.licence_plate,
			} as Vozilo;
		});
	}, [vehicles]);

	const columns: GridColDef[] = [
		{ field: "brand", headerName: "Znamka", minWidth: 150, flex: 1 },
		{ field: "licence_plate", headerName: "Registrska tablica", minWidth: 150, flex: 1 },
		{
			field: "options",
			headerName: "Možnosti",
			maxWidth: 90,
			flex: 1,
			renderCell: (params: any) => {
				return (
					<>
						<IconButton aria-label="edit" size="small" onClick={() => handleEditClick(params.id)}>
							<EditIcon fontSize="inherit" />
						</IconButton>
						<IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(params.id)}>
							<DeleteIcon fontSize="inherit" />
						</IconButton>
					</>
				);
			},
		},
	];

	const handleAddClick = () => {
		setEditingObject(null);
		setAddOrEditMode("add");
	};

	const handleEditClick = (id: string) => {
		const foundUser = rows.find((u) => u.id === id);

		if (foundUser) {
			setEditingObject(foundUser);
			setAddOrEditMode("edit");
		}
	};

	const handleDeleteClick = async (id: string) => {
		const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati vozilo?");

		if (shouldDelete) {
			const res = await deleteDoc(doc(getFirestore(firebaseClient), "vehicle", id));
		}
	};

	const create = async (dogodek: Vozilo) => {
		if (!dogodek) {
			setAddOrEditMode(null);
			return;
		}

		const withoutId = dogodek;
		delete withoutId.id;
		const res = await addDoc(collection(getFirestore(firebaseClient), "vehicle"), withoutId);
		setAddOrEditMode(null);
	};

	const update = async (dogodek: Vozilo) => {
		if (!dogodek?.id) {
			setAddOrEditMode(null);
			return;
		}
		const found = doc(getFirestore(firebaseClient), "vehicle", dogodek.id!);
		const res = await updateDoc(found, { ...dogodek });
		setAddOrEditMode(null);
	};

	return (
		<div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
			<h1 className="mb-6 text-3xl">
				Vozila
				<IconButton aria-label="add" size="small" onClick={handleAddClick}>
					<AddIcon fontSize="inherit" />
				</IconButton>
			</h1>
			{addOrEditMode && (
				<AddEditComponent mode={addOrEditMode} onSave={addOrEditMode == "edit" ? update : create} editingObject={editingObject} />
			)}
			{rows && <DataGrid localeText={tableTranslations} rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[5]} />}
		</div>
	);
}

export interface Vozilo {
	id?: string;
	brand?: string;
	licence_plate?: string;
}
