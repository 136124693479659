import { useEffect, useMemo, useState } from "react";
import { Tooltip } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, deleteDoc, doc, getDocs, getFirestore, limit, orderBy, query, Timestamp, QueryDocumentSnapshot, DocumentData, onSnapshot } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(firebaseClient);

const PAGE_SIZE = 13;

export default function OdhodiPrihodi() {
	// auth check
	const navigate = useNavigate();
	const [user, userLoading, userError] = useAuthState(auth);
	if (!user) navigate("/prijava");

	const [users] = useCollection(collection(getFirestore(firebaseClient), "profiles"));
	const [value, setValue] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
        const q = query(
            collection(getFirestore(firebaseClient), "base"),
            orderBy("timestamp", "desc"), // Replace "timestamp" with a field to order by
            limit(PAGE_SIZE)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setValue(querySnapshot.docs);
            setLoading(false);
        }, (err) => {
            setError(err instanceof Error ? err : new Error(String(err)));
            setLoading(false);
        });

        // Clean up the subscription on unmount
        return () => unsubscribe();
    }, []);

	useEffect(() => {
		if (!loading) {
			const loadAllData = async () => {
				const q = query(
					collection(getFirestore(firebaseClient), "base"),
					orderBy("timestamp", "desc") // Replace "timestamp" with a field to order by
				);

				try {
					const querySnapshot = await getDocs(q);
					setValue(querySnapshot.docs);
				} catch (err) {
					setError(err instanceof Error ? err : new Error(String(err)));
					setLoading(false);
				}
			};

			loadAllData();
		}
	}, [loading]);

	const rows: Aktivnost[] = useMemo(() => {
		if (!value || error) return [];

		return value?.map((doc) => {
			const data = doc.data();

			let user;
			users?.docs.map((docUser) => {
				const dataUser = docUser.data();
				if (dataUser.user_id == data.user_id) {
					user = dataUser;
				}
			});

			return {
				id: doc.id,
				action: data.action,
				event: data.event,
				user: `${user ? user!.name + ' - ' + user!.email : '/'}`,
				timestamp: data.timestamp,
				notes: data.notes,
			} as Aktivnost;
		});
	}, [value, users, error]);

	const columns: GridColDef[] = [
		{ field: "event", headerName: "Ime dogodka", minWidth: 150, flex: 1 },
		{ field: "action", headerName: "Aktivnost", minWidth: 150, flex: 1 },
		{ field: "user", headerName: "Uporabnik", minWidth: 250, flex: 1 },
		{
			field: "notes", headerName: "Opombe", minWidth: 150, flex: 1, renderCell: (params: any) => {
				return <Tooltip title={params?.value}><p>{params?.value}</p></Tooltip>;
			},
		},
		{
			field: "timestamp",
			headerName: "Čas",
			minWidth: 160,
			flex: 1,
			renderCell: (params: any) => {
				return <p>{params?.value?.toDate()?.toLocaleString() ?? "/"}</p>;
			},
		},
	];

	const handleDeleteClick = async (id: string) => {
		const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati aktivnost?");

		if (shouldDelete) {
			const res = await deleteDoc(doc(getFirestore(firebaseClient), "base", id));
		}
	};

	return (
		<div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
			<h1 className="mb-6 text-3xl">Odhodi in prihodi</h1>
			{rows && <DataGrid localeText={tableTranslations} rows={rows} columns={columns} pageSize={13} rowsPerPageOptions={[5]} sortModel={[{ 'field': 'timestamp', 'sort': 'desc', }]} />}
		</div>
	);
}

export interface Aktivnost {
	id?: string;
	action?: string;
	event?: string;
	user?: string;
	timestamp?: Timestamp;
	notes?: string;
}
