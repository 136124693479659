import React, { useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { addDoc, collection, deleteDoc, doc, getFirestore, limit, orderBy, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddEditComponent from "./AddEditComponent";
import { deleteUser, getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import NotificationComponent from "./NotificationComponent";
import { Vozilo } from "../VozilaPage";
import { getDayName } from "../../misc/helpers";
import ConfirmedUsersDialog from "./ConfirmedUsersDialog";

const auth = getAuth(firebaseClient);

export default function DogodkiPage() {
	// auth check
	const navigate = useNavigate();
	const [user, userLoading, userError] = useAuthState(auth);
	if (!user) navigate("/prijava");

	const [loadForNumDays, setLoadForNumDays] = useState(60);

	const [confirmedUsersDialog, setConfirmedUsersDialog] = useState<any[]>([]);
	const [vehicleDocs, vehiclesLoading] = useCollection(collection(getFirestore(firebaseClient), "vehicle"));
	const [profileDocs] = useCollection(collection(getFirestore(firebaseClient), "profiles"));
	const [eventResponses] = useCollection(collection(getFirestore(firebaseClient), "event_responses"));

	const vehicles: Vozilo[] = useMemo(() => {
		if (!vehicleDocs) return [];
		return vehicleDocs.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				brand: data.brand,
				licence_plate: data.licence_plate,
			} as Vozilo;
		});
	}, [vehicleDocs]);

	// base entries (base leave, event arrive,...)
	const qBase = useMemo(() => query(
		collection(getFirestore(firebaseClient), "base"),
		where("timestamp", ">", Timestamp.fromDate(new Date(Date.now() - loadForNumDays * 24 * 60 * 60 * 1000))),
		orderBy("timestamp", "desc"),
	), [loadForNumDays]);
	const [baseDataDocs, baseLoading] = useCollection(qBase);

	// const [eventDocs, loading, error] = useCollection(collection(getFirestore(firebaseClient), "events"));
	const qEvents = useMemo(() => query(
		collection(getFirestore(firebaseClient), "events"),
		where("start_timestamp", ">=", Timestamp.fromDate(new Date(Date.now() - loadForNumDays * 24 * 60 * 60 * 1000))),
		orderBy("start_timestamp", "desc")
	  ), [loadForNumDays]);
	  
	  const [eventDocs, eventsLoading, error] = useCollection(qEvents);
	  
	const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
	const [editingObject, setEditingObject] = useState<Dogodek | null>(null);
	const [sendSmsEvent, setSendSmsEvent] = useState<null | Dogodek>(null);

	const rows: Dogodek[] = useMemo(() => {
		if (!eventDocs || error) return [];

		var eventsSorted = eventDocs.docs.sort((a, b) => {
			// sort based on start_timestamp
			var aData = a.data();
			var bData = b.data();

			if (aData.start_timestamp < bData.start_timestamp) return 1;
			if (aData.start_timestamp > bData.start_timestamp) return -1;
			return 0;
		});

		const final = eventsSorted?.map((eventDoc) => {
			const eventData = eventDoc.data();
			const eventVehicles: Vozilo[] = vehicles.filter((vehicle) => eventData?.vehicle_ids?.includes(vehicle.id)) ?? [];
			eventVehicles.push({} as Vozilo);

			// =================================================

			let userData: any[] = [];
			eventVehicles.forEach((vehicle) => {
				var _tmpUserData: LooseObject = {
					vehicle: vehicle,
					users: [],
				}

				// get all the entries for current event & vehicle
				var baseLeaves = baseDataDocs?.docs.filter((baseDoc) => baseDoc.data().event === eventData.name && baseDoc.data().action === "Odhod iz baze" && baseDoc.data().vehicle_id == vehicle.id);
				var eventArrivals = baseDataDocs?.docs.filter((baseDoc) => baseDoc.data().event === eventData.name && baseDoc.data().action === "Prihod na prireditev" && baseDoc.data().vehicle_id == vehicle.id);
				var eventLeaves = baseDataDocs?.docs.filter((baseDoc) => baseDoc.data().event === eventData.name && baseDoc.data().action === "Odhod iz prireditve" && baseDoc.data().vehicle_id == vehicle.id);
				var baseArrivals = baseDataDocs?.docs.filter((baseDoc) => baseDoc.data().event === eventData.name && baseDoc.data().action === "Prihod v bazo" && baseDoc.data().vehicle_id == vehicle.id);

				baseLeaves?.forEach((baseLeaveDoc) => {
					var baseLeaveData = baseLeaveDoc.data();
					var foundUser = _tmpUserData.users.find((user: any) => user.user_id === baseLeaveData.user_id);
					var userProfile = profileDocs?.docs.find((profileDoc) => profileDoc.data().user_id === baseLeaveData.user_id);


					if (foundUser) {
						foundUser.base_leave = baseLeaveData;
					} else {
						_tmpUserData.users.push({
							user_id: baseLeaveData.user_id,
							user: userProfile?.data(),
							base_leave: baseLeaveData,
						});
					}
				});

				eventArrivals?.forEach((eventArrivalDoc) => {
					var eventArrivalData = eventArrivalDoc.data();
					var foundUser = _tmpUserData.users.find((user: any) => user.user_id === eventArrivalData.user_id);
					var userProfile = profileDocs?.docs.find((profileDoc) => profileDoc.data().user_id === eventArrivalData.user_id);

					if (foundUser) {
						foundUser.event_arrival = eventArrivalData;
					} else {
						_tmpUserData.users.push({
							user_id: eventArrivalData.user_id,
							user: userProfile?.data(),
							event_arrival: eventArrivalData,
						});
					}
				});

				eventLeaves?.forEach((eventLeaveDoc) => {
					var eventLeaveData = eventLeaveDoc.data();
					var foundUser = _tmpUserData.users.find((user: any) => user.user_id === eventLeaveData.user_id);
					var userProfile = profileDocs?.docs.find((profileDoc) => profileDoc.data().user_id === eventLeaveData.user_id);

					if (foundUser) {
						foundUser.event_leave = eventLeaveData;
					} else {
						_tmpUserData.users.push({
							user_id: eventLeaveData.user_id,
							user: userProfile?.data(),
							event_leave: eventLeaveData,
						});
					}
				});

				baseArrivals?.forEach((baseArrivalDoc) => {
					var baseArrivalData = baseArrivalDoc.data();
					var foundUser = _tmpUserData.users.find((user: any) => user.user_id === baseArrivalData.user_id);
					var userProfile = profileDocs?.docs.find((profileDoc) => profileDoc.data().user_id === baseArrivalData.user_id);

					if (foundUser) {
						foundUser.base_arrival = baseArrivalData;
					} else {
						_tmpUserData.users.push({
							user_id: baseArrivalData.user_id,
							user: userProfile?.data(),
							base_arrival: baseArrivalData,
						});
					}
				});

				userData.push(_tmpUserData);
			})


			// =================================================
			// all confirmed users
			var confirmedResponses = eventResponses?.docs.filter((responseDoc) => responseDoc.data().event_id === eventDoc.id && responseDoc.data().selected === true);
			var confirmedUsers = confirmedResponses?.map((responseDoc) => profileDocs?.docs.find((profiledoc) => responseDoc.data().user_id === profiledoc.data().user_id)?.data());

			return {
				id: eventDoc.id,
				key: eventDoc.id,
				name: eventData.name,
				start_day_name: getDayName(eventData.start_timestamp?.toDate()),
				location: eventData.location,
				address: eventData.address,
				contact: eventData.contact,
				start_timestamp: eventData.start_timestamp,
				end_timestamp: eventData.end_timestamp,
				num_sent_notifications: eventData.num_sent_notifications ?? 0,
				base_leave_time: eventData.base_leave_time,
				leave_hour_confirmed: eventData.leave_hour_confirmed,
				event_type: eventData.event_type,
				organizer: eventData.organizer,
				account_number: eventData.account_number,
				account_balance: eventData.account_balance,
				note: eventData.note,
				mapped_user_data: userData,
				row_color: eventData.row_color,
				confirmed_users: confirmedUsers,
			} as Dogodek;
		});

		return final;
	}, [eventDocs, baseDataDocs, vehicleDocs]);

	const columns: GridColDef[] = [
		{
			field: "options",
			headerName: "Možnosti",
			width: 250,

			renderCell: (params: any) => {
				return (
					<>
						<IconButton aria-label="edit" size="small" onClick={() => handleEditClick(params.id)}>
							<EditIcon fontSize="inherit" />
						</IconButton>
						<IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(params.id)}>
							<DeleteIcon fontSize="inherit" />
						</IconButton>
						<button onClick={() => handleSendNotificationClick(params)} className="text-blue-600 underline ml-2 disabled:text-gray-600">Pošlji obvestilo</button>
						<Link to={"/odzivi-na-dogodek/" + params.id} className="text-blue-600" style={{ marginLeft: '12px', textDecoration: 'underline' }}>
							Odzivi
						</Link>
					</>
				);
			},
		},
		{ field: "name", headerName: "Ime dogodka", width: 250 },
		{
			field: "start_timestamp",
			headerName: "Datum dogodka",
			width: 160,

			renderCell: (params: any) => {
				const date: Date = params?.value?.toDate();
				return <p>{`${date.toLocaleDateString("sl-SI")}`}</p>;
			},
		},
		{ field: "start_day_name", headerName: "Dan dogodka", width: 150 },
		{
			field: "end_timestamp",
			headerName: "Čas dogodka",
			width: 160,

			renderCell: (params: any) => {
				const start: Date = params.row.start_timestamp?.toDate();
				const end: Date = params.row.end_timestamp?.toDate();
				return <p>{`${start.getHours().toString().padStart(2, "0")}:${start.getMinutes().toString().padStart(2, "0")} - ${end.getHours().toString().padStart(2, "0")}:${end.getMinutes().toString().padStart(2, "0")}`}</p>;
			},
		},
		{ field: "base_leave_time", headerName: "Odhod iz baze", width: 140 },
		{
			field: "leave_hour_confirmed", headerName: "Preverba ure", width: 180, renderCell: (params: any) => {
				return <select onChange={(e) => {
					var selected = e.target.value == '1';
					const found = doc(getFirestore(firebaseClient), "events", params.row.id);
					updateDoc(found, { leave_hour_confirmed: selected });
				}}>
					<option value="0" selected={!params.value}>Preveri ure</option>
					<option value="1" selected={params.value}>Ure OK</option>
				</select>
			}
		},
		{
			field: "confirmed_users", headerName: "Potrjen kader", width: 145, renderCell: (params: any) => {
				return <Button variant="outlined" size="small" type="submit" disabled={params?.row?.confirmed_users?.length <= 0} onClick={() => {
					setConfirmedUsersDialog(params?.row?.confirmed_users ?? []);
				}}>Prikaži kader</Button>
				// return <div>
				// 	{params.row.confirmed_users.map((user: any) => {
				// 		return <div style={{ marginBottom: '4px', padding: '4px 0' }}>{user?.name} ({user?.category})</div>;
				// 	})}
				// </div>
			}
		},
		{
			field: "vehicle_names", headerName: "Razpisano vozilo", width: 300, renderCell: (params: any) => {
				return <div>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						const spacingBottom = vehicleObj.users.length > 1 ? (vehicleObj.users.length - 1) * 20 : 0;

						return <div style={{ marginBottom: `${spacingBottom}px`, padding: '8px 0' }}>{vehicleObj.vehicle.brand} ({vehicleObj.vehicle.licence_plate})</div>;
					})}
				</div>
			}
		},
		{
			field: "kader_kategorija", headerName: "Kader (kategorija)", width: 180, renderCell: (params: any) => {
				return <div style={{ padding: '16px' }}>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						// vehicle container
						return <div style={{ minHeight: '20px', padding: '8px 0' }}>
							{vehicleObj.users.length <= 0 ? <div style={{ height: '20px' }}>/</div> : null}
							{vehicleObj.users.map((userObj: any) => {
								// user container
								return <div style={{ height: '20px' }}>{userObj?.user?.category ?? '/'}</div>
							})}
						</div>
					})}
				</div>
			}
		},
		{
			field: "kader_oseba", headerName: "Kader (oseba)", width: 150, renderCell: (params: any) => {
				return <div style={{ padding: '16px' }}>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						// vehicle container
						return <div style={{ minHeight: '20px', padding: '8px 0' }}>
							{vehicleObj.users.length <= 0 ? <div style={{ height: '20px' }}>/</div> : null}
							{vehicleObj.users.map((userObj: any) => {
								// user container
								return <div style={{ height: '20px' }}>{userObj?.user?.name ?? '/'}</div>
							})}
						</div>
					})}
				</div>
			}
		},
		{
			field: "kader_phone", headerName: "Kader (tel. št.)", width: 150, renderCell: (params: any) => {
				return <div style={{ padding: '16px' }}>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						// vehicle container
						return <div style={{ minHeight: '20px', padding: '8px 0' }}>
							{vehicleObj.users.length <= 0 ? <div style={{ height: '20px' }}>/</div> : null}
							{vehicleObj.users.map((userObj: any) => {
								// user container
								return <div style={{ height: '20px' }}>{userObj?.user?.phone_number ?? '/'}</div>
							})}
						</div>
					})}
				</div>
			}
		},
		{ field: "location", headerName: "Lokacija", width: 200 },
		{ field: "address", headerName: "Naslov", width: 200 },
		{ field: "event_type", headerName: "Tip dogodka", width: 150 },
		{ field: "organizer", headerName: "Organizator", width: 250 },
		{ field: "contact", headerName: "Kontakt organizatorja", width: 200 },
		{ field: "account_number", headerName: "Številka računa", width: 150 },
		{ field: "account_balance", headerName: "Znesek računa", width: 150 },
		{
			field: "base_departure_time", headerName: "Ura odhoda iz baze", width: 200, renderCell: (params: any) => {
				return <div style={{ padding: '16px' }}>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						// vehicle container
						return <div style={{ minHeight: '20px', padding: '8px 0' }}>
							{vehicleObj.users.length <= 0 ? <div style={{ height: '20px' }}>/</div> : null}
							{vehicleObj.users.map((userObj: any) => {
								// user container
								var date: Date = userObj?.base_leave?.timestamp?.toDate();
								return <div style={{ height: '20px' }}>{date ? `${date.getHours()}:${date.getMinutes()}` : '/'}</div>
							})}
						</div>
					})}
				</div>
			}
		},
		{
			field: "event_arrival_time", headerName: "Ura prihoda na prireditev", width: 200, renderCell: (params: any) => {
				return <div style={{ padding: '16px' }}>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						// vehicle container
						return <div style={{ minHeight: '20px', padding: '8px 0' }}>
							{vehicleObj.users.length <= 0 ? <div style={{ height: '20px' }}>/</div> : null}
							{vehicleObj.users.map((userObj: any) => {
								var date: Date = userObj?.event_arrival?.timestamp?.toDate();
								return <div style={{ height: '20px' }}>{date ? `${date.getHours()}:${date.getMinutes()}` : '/'}</div>
							})}
						</div>
					})}
				</div>
			}
		},
		{
			field: "event_departure_time", headerName: "Ura odhoda iz prireditve", width: 200, renderCell: (params: any) => {
				return <div style={{ padding: '16px' }}>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						// vehicle container
						return <div style={{ minHeight: '20px', padding: '8px 0' }}>
							{vehicleObj.users.length <= 0 ? <div style={{ height: '20px' }}>/</div> : null}
							{vehicleObj.users.map((userObj: any) => {
								// user container
								var date: Date = userObj?.event_leave?.timestamp?.toDate();
								return <div style={{ height: '20px' }}>{date ? `${date.getHours()}:${date.getMinutes()}` : '/'}</div>
							})}
						</div>
					})}
				</div>
			}
		},
		{
			field: "base_arrival_time", headerName: "Ura prihoda v bazo", width: 150, renderCell: (params: any) => {
				return <div style={{ padding: '16px' }}>
					{params.row.mapped_user_data.map((vehicleObj: any) => {
						// vehicle container
						return <div style={{ minHeight: '20px', padding: '8px 0' }}>
							{vehicleObj.users.length <= 0 ? <div style={{ height: '20px' }}>/</div> : null}
							{vehicleObj.users.map((userObj: any) => {
								// user container
								var date: Date = userObj?.base_arrival?.timestamp?.toDate();
								return <div style={{ height: '20px' }}>{date ? `${date.getHours()}:${date.getMinutes()}` : '/'}</div>
							})}
						</div>
					})}
				</div>
			}
		},
		{ field: "note", headerName: "Opombe", width: 250 },
		{ field: "num_sent_notifications", headerName: "Št obvestil", width: 100 },
	];

	const handleSendNotificationClick = (params: any) => {
		const event = params.row as Dogodek;
		setSendSmsEvent(event!);
	}

	const handleAddClick = () => {
		setEditingObject(null);
		setAddOrEditMode("add");
	};

	const handleEditClick = (id: string) => {
		const foundEvent = rows.find((u) => u.id === id);

		if (foundEvent) {
			setEditingObject(foundEvent);
			setAddOrEditMode("edit");
		}
	};

	const handleDeleteClick = async (id: string) => {
		const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati dogodek?");

		if (shouldDelete) {
			const res = await deleteDoc(doc(getFirestore(firebaseClient), "events", id));
		}
	};

	const create = async (dogodek: Dogodek) => {
		if (!dogodek) {
			setAddOrEditMode(null);
			return;
		}

		const withoutId = dogodek;
		delete withoutId.id;
		const res = await addDoc(collection(getFirestore(firebaseClient), "events"), withoutId);
		setAddOrEditMode(null);
	};

	const update = async (dogodek: Dogodek) => {
		if (!dogodek?.id) {
			setAddOrEditMode(null);
			return;
		}
		const found = doc(getFirestore(firebaseClient), "events", dogodek.id!);
		const res = await updateDoc(found, { ...dogodek });
		setAddOrEditMode(null);
	};


	return (
		<div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
			<div className="flex flex-row justify-between">
				<h1 className="text-3xl">
					Dogodki
					<IconButton aria-label="add" size="small" onClick={handleAddClick}>	
						<AddIcon fontSize="inherit" />
					</IconButton>
				</h1>

				{/* select that sets setLoadForNumDays to 30, 60, 120, and all */}
				<div className="mt-6 mb-3">
					<label htmlFor="loadForNumDays" className="mr-2">Naloži dogodke za zadnjih</label>
					<select className="p-2" name="loadForNumDays" id="loadForNumDays" onChange={(e) => setLoadForNumDays(parseInt(e.target.value))} value={loadForNumDays}>
						<option value="30">30 dni</option>
						<option value="60">60 dni</option>
						<option value="120">120 dni</option>
						<option value="10000">vse</option>
					</select>

					{/* if baseLoading || eventsLoading, show loading indicator */}
					{baseLoading || eventsLoading ? <div className="ml-3 inline-block animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900"></div> : null}
				</div>
			</div>
			{addOrEditMode && (
				<AddEditComponent mode={addOrEditMode} onSave={addOrEditMode == "edit" ? update : create} editingObject={editingObject} vehicles={vehicles} />
			)}
			{rows && <DataGrid
				showColumnRightBorder={true}
				showCellRightBorder={true}
				localeText={tableTranslations}
				rows={rows}
				columns={columns}
				getRowHeight={() => 'auto'}
				pageSize={25}
				rowsPerPageOptions={[5]}
				style={{ fontSize: '16px' }}
				getRowClassName={(params) =>
					params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
				}
			/>}

			<Dialog open={sendSmsEvent != null} onClose={() => setSendSmsEvent(null)}>
				<NotificationComponent event={sendSmsEvent!} closeDialog={() => setSendSmsEvent(null)} />
			</Dialog>

			<Dialog open={confirmedUsersDialog?.length > 0} onClose={() => setSendSmsEvent(null)}>
				<ConfirmedUsersDialog closeDialog={() => setConfirmedUsersDialog([])} users={confirmedUsersDialog} />
			</Dialog>

			<style>
				{
					rows.map((row) => {
						if (row.row_color == null) return '';
						return `
							div[data-id="${row.id}"], div[data-id="${row.id}"].Mui-selected {
								background-color: ${row.row_color} !important;
							}

							div[data-id="${row.id}"]:hover {
								background-color: ${row.row_color} !important;
								opacity: 0.9;
							}
						`;
					})
				}
			</style>
		</div>
	);
}

export interface Dogodek {
	id?: string;
	name?: string;
	location?: string;
	address?: string;
	contact?: string;
	start_timestamp?: Timestamp;
	end_timestamp?: Timestamp;
	num_sent_notifications?: number;
	base_leave_time?: string;
	vehicle_ids?: string[];
	leave_hour_confirmed?: boolean;
	event_type?: string;
	organizer?: string;
	account_number?: string;
	account_balance?: string;
	note?: string;
	row_color?: string;

}


interface LooseObject {
	[key: string]: any
}