import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { BrowserRouter as Router, Link, Navigate, NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UporabnikiPage from "./pages/UporabnikiPage";
import PrijavaPage from "./pages/PrijavaPage";
import firebaseClient from "./services/firebaseClient";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import DogodkiPage from "./pages/DogodkiPage";
import OdhodiPrihodi from "./pages/OdhodiPrihodiPage";
import VozilaPage from "./pages/VozilaPage";
import PorocilaVozilPage from "./pages/PorocilaVozilPage";
import RazpolozljivostPage from "./pages/RazpolozljivostPage";
import OdziviNaDogodkePage from "./pages/OdziviNaDogodkePage";
import DogodekOdzivPage from "./pages/DogodekOdzivPage";

const auth = getAuth(firebaseClient);

const pages = [
  {
    name: "Uporabniki",
    path: "/uporabniki",
    component: UporabnikiPage,
    visibleInNav: true,
  },
  {
    name: "Dogodki",
    path: "/dogodki",
    component: DogodkiPage,
    visibleInNav: true,
  },
  {
    name: "Odzivi na dogodek",
    path: "/odzivi-na-dogodek/:eventId",
    component: OdziviNaDogodkePage,
    visibleInNav: false,
  },
  {
    name: "Odhodi in prihodi",
    path: "/odhodi-prihodi",
    component: OdhodiPrihodi,
    visibleInNav: true,
  },
  {
    name: "Razpoložljivost",
    path: "/razpolozljivost",
    component: RazpolozljivostPage,
    visibleInNav: true,
  },
  {
    name: "Vozila",
    path: "/vozila",
    component: VozilaPage,
    visibleInNav: true,
  },
  {
    name: "Poročila vozil",
    path: "/porocila-vozil",
    component: PorocilaVozilPage,
    visibleInNav: true,
  },
  {
    name: "Dogodki - odziv uporabnika",
    path: "/dogodek/odziv",
    component: DogodekOdzivPage,
    visibleInNav: false,
  },
  {
    name: "Prijava",
    path: "/prijava",
    component: PrijavaPage,
    visibleInNav: false,
  },
  {
    name: "/",
    path: "/",
    component: PrijavaPage,
    visibleInNav: false,
  },
];
const menuNotVisibleOnPaths = ["/dogodek/odziv", '/prijava', '/'];

const App = () => {
  const location = useLocation();
  const [user, userLoading, userError] = useAuthState(auth);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div className="h-screen">
      <AppBar position="static" style={{ background: "rgb(84, 102, 136)", color: "white" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="p"
              sx={{
                mr: 8,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              ASMEDIC
            </Typography>

            {!menuNotVisibleOnPaths.includes(location.pathname) && <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {/* mobile */}
                {user &&
                  pages.map((page, i) => (
                    <MenuItem key={i} onClick={handleCloseNavMenu}>
                      <Link to={page.path}>{page.name}</Link>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>}
            <Typography
              variant="h5"
              noWrap
              component="p"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              ASMEDIC
            </Typography>
            {/* desktop menu */}
            {user && !menuNotVisibleOnPaths.includes(location.pathname) && (
              <Box sx={{ flexGrow: 1, justifyContent: "space-between", alignItems: 'center', gap: '16px', display: { xs: "none", md: "flex" } }}>
                {pages.map(
                  (page, i) =>
                    page.visibleInNav && (
                      <NavLink key={i} to={page.path} className={({ isActive }) => (isActive ? "nav-active" : "nav-inactive")}>
                        {page.name}
                      </NavLink>
                    )
                )}
                <NavLink
                  to={"/prijava"}
                  onClick={() => {
                    const auth = getAuth();
                    signOut(auth);
                  }}
                >
                  Odjava
                </NavLink>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Routes>
        {pages.map((page, i) => (
          <Route key={i} path={page.path} element={<page.component />} />
        ))}
      </Routes>
    </div>
  );
};
export default App;
