import { Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import firebaseService from "../../services/firebaseService";

export default function DogodekOdzivPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = useMemo(() => searchParams.get("id"), [searchParams]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [event, setEvent] = useState<any>(null);

  useEffect(() => {
    if (!id) return;

    firebaseService.getDocumentById("event_responses", id!).then((doc) => {
      const data = doc.data();
      if (data?.user_response != null) {
        setErrorMessage("Na ta dogodek ste se že odzvali.");
      } else {
        setErrorMessage(null);
        firebaseService.getDocumentById("events", data?.event_id).then((eventDoc) => {
          setEvent(eventDoc.data());
        });
      }
    });
  }, [id]);

  
  const onResponseClick = async (response: boolean) => {
    await firebaseService.updateDocument("event_responses", id!, {"user_response": response});

    setErrorMessage('Hvala za vaš odziv!');
  }
  
  if (errorMessage) return <div className="text-center mt-16 text-3xl">{errorMessage}</div>;
  if (!event) return null;

  return (
    <>
      {event && (
        <h2 className="text-center mt-8">
          Odziv na dogodek: <strong>{event.name}</strong>
        </h2>
      )}
      <div className="text-center flex justify-center gap-8 mt-12">
        <Button variant="contained" type="submit" style={{ background: "#50b534" }} onClick={() =>onResponseClick(true)}>
          PRIDEM
        </Button>
        <Button variant="contained" type="submit" style={{ background: "#d32525" }} onClick={() =>onResponseClick(false)}>
          NE PRIDEM
        </Button>
      </div>
    </>
  );
}
