import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, TextFieldProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Dogodek } from ".";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { collection, getFirestore, Timestamp } from "firebase/firestore";
import { signInWithEmailAndPassword, PhoneAuthProvider } from "firebase/auth";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useCollection } from "react-firebase-hooks/firestore";
import firebaseClient from "../../services/firebaseClient";
import { Vozilo } from "../VozilaPage";
import ColorPicker from 'material-ui-color-picker'

interface Props {
	mode: "add" | "edit";
	onSave: ({ }: any) => void;
	editingObject?: Dogodek | null;
	vehicles: Vozilo[];
}

export default function AddEditComponent(props: Props) {
	const [object, setObject] = useState({} as Dogodek);
	const formRef = useRef<HTMLFormElement>(null);

	

	useEffect(() => {
		setObject(props.editingObject ?? ({} as Dogodek));
		window.scrollTo(0, 0);
	}, [props.editingObject]);

	const handleSave = (e: any) => {
		e.preventDefault();
		if (!object.start_timestamp || !object.end_timestamp) {
			alert("Izpolnite vsa zahtevana polja!");
		} else {
			// in object, remove any properties that are undefined
			const removedUndefiend: Dogodek = removeUndefinedProperties(object);
			props.onSave(removedUndefiend);
		}

	};

	const handleCancel = () => {
		props.onSave(null);
	};

	return (
		<div className="mt-4 mb-8 p-4 shadow-md bg-gray-50 border-gray-200 border">
			<form onSubmit={handleSave} ref={formRef}>
				<h2>{props.mode == "add" ? "Dodaj" : "Uredi"} dogodek</h2>
				<div className="form flex flex-col gap-4 my-4">
					<TextField
						label="Ime dogodka"
						type="text"
						required={true}
						value={object.name ?? ""}
						onChange={(e) => setObject({ ...object, name: e.target.value })}
					/>
					<TextField
						label="Lokacija"
						type="text"
						required={true}
						value={object.location ?? ""}
						onChange={(e) => setObject({ ...object, location: e.target.value })}
					/>
					<TextField
						label="Naslov"
						type="text"
						required={true}
						value={object.address ?? ""}
						onChange={(e) => setObject({ ...object, address: e.target.value })}
					/>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DateTimePicker
							ampm={false}
							inputFormat="DD.MM.YYYY HH:mm"
							label="Začetek *"
							value={object.start_timestamp ? moment(object.start_timestamp?.toDate()) : null}
							onChange={(val) => val && setObject({ ...object, start_timestamp: Timestamp.fromDate(val!.toDate()) })}
							renderInput={(params) => <TextField {...params} />}
						/>
						<DateTimePicker
							ampm={false}
							inputFormat="DD.MM.YYYY HH:mm"
							label="Konec *"
							value={object.end_timestamp ? moment(object.end_timestamp?.toDate()) : null}
							onChange={(val) => val && setObject({ ...object, end_timestamp: Timestamp.fromDate(val!.toDate()) })}
							renderInput={(params) => <TextField {...params} />}
						/>
						{/*  new fields */}
						<TextField
							label="Predvidena ura odhoda"
							type="time"
							value={object.base_leave_time ?? '00:00'}
							onChange={(e) => {
								setObject({ ...object, base_leave_time: e.target.value })
							}}
						/>

						<FormControlLabel
							control={
								<Checkbox
								checked={object.leave_hour_confirmed ?? false}
								onChange={(e) => setObject({ ...object, leave_hour_confirmed: e.target.checked })}
								/>
							}
							label="Ure potrjene"
						/>

						<FormControl fullWidth>
							<InputLabel>Razpisana vozila</InputLabel>
							<Select
								label={'Razpisana vozila'}
								multiple
								onChange={(e: any) => {
									if (e.target.value.length < 5) {
										setObject({ ...object, vehicle_ids: e.target.value })
									}
								}}
								value={object.vehicle_ids ?? []}
							>
								{props.vehicles.map((vehicle) => (
									<MenuItem value={vehicle.id}>{vehicle.brand} ({vehicle.licence_plate})</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							label="Tip dogodka"
							type="text"
							value={object.event_type}
							onChange={(e) => {
								setObject({ ...object, event_type: e.target.value })
							}}
						/>
						<TextField
							label="Organizator"
							type="text"
							value={object.organizer}
							onChange={(e) => {
								setObject({ ...object, organizer: e.target.value })
							}}
						/>
						<TextField
							label="Kontakt organizatorja"
							type="text"
							value={object.contact ?? ""}
							onChange={(e) => setObject({ ...object, contact: e.target.value })}
						/>
						<TextField
							label="Številka računa"
							type="text"
							value={object.account_number}
							onChange={(e) => {
								setObject({ ...object, account_number: e.target.value })
							}}
						/>
						<TextField
							label="Znesek računa"
							type="text"
							value={object.account_balance}
							onChange={(e) => {
								setObject({ ...object, account_balance: e.target.value })
							}}
						/>

						<TextField
							label="Opomba"
							type="text"
							value={object.note}
							onChange={(e) => {
								setObject({ ...object, note: e.target.value })
							}}
						/>

						<TextField
							label="Barva vrstice"
							type="color"
							className="w-[120px]"
							value={object.row_color ?? '#ffffff'}
							onChange={(e) => {
								setObject({ ...object, row_color: e.target.value })
							}}
						/>

					</LocalizationProvider>
				</div>

				<div className="flex flex-row gap-4">
					<Button variant="contained" type="submit">
						Shrani
					</Button>
					<Button variant="contained" onClick={handleCancel}>
						Prekliči
					</Button>
				</div>
			</form>
		</div>
	);
}


const removeUndefinedProperties = <T extends object>(obj: T): T => {
	const filteredObj = Object.fromEntries(
	  Object.entries(obj).filter(([_, value]) => value !== undefined)
	);
  
	return filteredObj as T;
  };