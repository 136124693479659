import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddEditComponent from "./AddEditComponent";
import { createUserWithEmailAndPassword, deleteUser, getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import KategorijeEditComponent from "./KategorijeEditComponent";

const auth = getAuth(firebaseClient);

export default function UporabnikiPage() {
  // auth check
  const navigate = useNavigate();
  const [user, userLoading, userError] = useAuthState(auth);
  if (!user) navigate("/prijava");

  const [categoriesDocs, categoriesLoading, categoriesErr] = useCollection(
    collection(getFirestore(firebaseClient), "user_categories")
  );
  const categories = categoriesDocs?.docs.map(cat => cat.data().name);


  const [value, loading, error] = useCollection(
    collection(getFirestore(firebaseClient), "profiles")
  );
  const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
  const [editingUser, setEditingUser] = useState<ProfileData | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<any>();

  // custom notification
  const [sendNotificationDialogVisible, setSendNotificationDialogVisible] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");

  // these are users stored in firestore, not from firebase auth
  const userProfiles: ProfileData[] = useMemo(() => {
    if (!value || error) return [];

    return value?.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        name: data.name,
        email: data.email,
        phone_number: data.phone_number,
        role: data.role,
        active: data.active,
        user_id: data.user_id,
		category: data.category,
      } as ProfileData;
    });
  }, [value]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Ime", minWidth: 150, flex: 1 },
    { field: "email", headerName: "Email", minWidth: 150, flex: 1 },
    {
      field: "phone_number",
      headerName: "Telefonska številka",
      minWidth: 150,
      flex: 1,
    },
    // { field: "role", headerName: "Dostop", minWidth: 120, flex: 1 },
	{ field: "category", headerName: "Kategorija", minWidth: 120, flex: 1 },
    {
      field: "active",
      headerName: "Aktiven",
      type: "checkbox",
      minWidth: 75,
      flex: 1,
      renderCell: (params: any) => (params.value ? "Da" : "Ne"),
    },
    {
      field: "options",
      headerName: "Možnosti",
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditClick(params.id)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDeleteClick(params.id)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleAddUserClick = () => {
    setEditingUser(null);
    setAddOrEditMode("add");
  };

  const handleEditClick = (id: string) => {
    const foundUser = userProfiles.find((u) => u.id === id);

    if (foundUser) {
      setEditingUser(foundUser);
      setAddOrEditMode("edit");
    }
  };

  const handleDeleteClick = async (id: string) => {
    const shouldDelete = window.confirm(
      "Ali ste prepričani, da želite izbrisati uporabnika?"
    );

    if (shouldDelete) {
      // delete profile
      const res = await deleteDoc(
        doc(getFirestore(firebaseClient), "profiles", id)
      );
    }
  };

  /**
   * Creates a new user and adds it to the database.
   * 
   * @param profile - The user object to be created.
   * @returns A boolean indicating whether the user was successfully created.
   */
  const createUser = async (profile: ProfileData) => {
    const tmpProfile = {...profile};
    tmpProfile.phone_number = !tmpProfile.phone_number?.startsWith('+386') ? '+386' + tmpProfile.phone_number : tmpProfile.phone_number;

    if (!profile || !profile.email || !profile.password) {
      setAddOrEditMode(null);
      return false;
    }

    if (userProfiles.find((u) => u.phone_number === tmpProfile.phone_number)) {
      alert('Uporabnik z to telefonsko številko že obstaja');
      return false;
    }

    const authUser = await createUserWithEmailAndPassword(auth, profile.email, profile.password);

    // Add the user's UID to the user object
    tmpProfile.user_id = authUser.user.uid;

    // Add the user data to the profiles collection
    const usrNoId = tmpProfile;
    usrNoId.role = "user";
    delete usrNoId.id;
    delete usrNoId.password; // Remove password before saving to Firestore
    const res = await addDoc(
      collection(getFirestore(firebaseClient), "profiles"),
      usrNoId
    );

    // Add the user to the whitelist
    const whitelistRes = await setDoc(
      doc(
        collection(getFirestore(firebaseClient), "whitelist"),
        usrNoId?.user_id
      ),
      {}
    );

    setAddOrEditMode(null);
  };

  /**
   * Updates a user's profile data.
   * 
   * @param user - The user's profile data to be updated.
   * @returns A boolean indicating whether the update was successful.
   */
  const updateProfile = async (user: ProfileData): Promise<boolean> => {
    console.log(user);
    const tmpUser = { ...user };
    tmpUser.phone_number = !tmpUser.phone_number?.startsWith('+386') ? '+386' + tmpUser.phone_number : tmpUser.phone_number;

    // if category is null or undefined, set it to empty string
    if (!tmpUser.category) {
      tmpUser.category = "";
    }

    if (!tmpUser?.id) {
      setAddOrEditMode(null);
      return false;
    }

    if (userProfiles.find((u) => u.phone_number === tmpUser.phone_number && tmpUser.phone_number !== editingUser?.phone_number)) {
      alert('Uporabnik z to telefonsko številko že obstaja');
      return false;
    }

    // update whitelist data
    const getUser = doc(getFirestore(firebaseClient), "profiles", tmpUser.id!);
    const whitelistRes = await setDoc(
      doc(
        collection(getFirestore(firebaseClient), "whitelist"),
        tmpUser?.user_id
      ),
      {}
    );
    console.log(3);

    // update user profile data
    const res = await updateDoc(getUser, { ...tmpUser });

    setAddOrEditMode(null);

    return true;
  };

  const handleSendNotificationClick = async () => {
    const data = new URLSearchParams({
		users: selectedUsers,
      	title: notificationTitle,
     	message: notificationMessage
    });
    const res = await fetch(
      "https://us-central1-as-medic.cloudfunctions.net/sendCustomNotificationToSelectedUsers?" +
        data
    );

	  setSendNotificationDialogVisible(false)
  };


  return (
    <div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
      <Dialog
        open={sendNotificationDialogVisible}
        onClose={() => setSendNotificationDialogVisible(false)}
      >
        <DialogTitle>Pošlji obvestilo</DialogTitle>
        <DialogContent>
          <div className="py-4 flex flex-col gap-4 w-[450px]">
            <TextField
              id="outlined-multiline-static"
              label="Naslov"
              fullWidth
              rows={4}
              onChange={(e) => setNotificationTitle(e.target.value)}
            />
            <TextField
              id="outlined-multiline-static"
              label="Vsebina"
              multiline
              fullWidth
              rows={4}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendNotificationClick}>Pošlji</Button>
        </DialogActions>
      </Dialog>

      <div className="mb-6 flex justify-between items-end">
        <h1 className="text-3xl">
          Uporabniki
          <IconButton
            aria-label="add"
            size="small"
            onClick={handleAddUserClick}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </h1>
        <Button disabled={(selectedUsers?.length ?? 0) <= 0} variant="outlined" size="small"  onClick={() => setSendNotificationDialogVisible(true)}>Pošlji obvestilo izbranim uporabnikom</Button>
      </div>
      {addOrEditMode && (
        <AddEditComponent
          mode={addOrEditMode}
          onSave={addOrEditMode == "edit" ? updateProfile : createUser}
          editingUser={editingUser}
		      categories={categories}
        />
      )}
      {userProfiles && (
        <DataGrid
          localeText={tableTranslations}
          rows={userProfiles}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(e) => setSelectedUsers(e)}
        />
      )}

	  <KategorijeEditComponent categories={categories} />
    </div>
  );
}

export interface ProfileData {
  id?: string;
  user_id?: string;
  name?: string;
  email?: string;
  password?: string;
  phone_number?: string;
  role?: string;
  active?: boolean;
  category?: string;
}
