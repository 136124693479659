import { Button, IconButton, TextField } from "@mui/material";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import React, { useState } from "react";
import firebaseClient from "../../services/firebaseClient";
import firebaseService from "../../services/firebaseService";
import AddIcon from "@mui/icons-material/Add";

interface Props {
  categories: any;
}

export default function KategorijeEditComponent(props: Props) {
  const [newCatName, setNewCatName] = useState("");
  const [addVisible, setaddVisible] = useState(false);

  const addCategory = (e: any) => {
    if (newCatName.length > 0 && !props.categories.includes(newCatName)) {
      firebaseService.addDocument("user_categories", { name: newCatName });
    }
    setNewCatName("");
  };
  const deleteCategory = async (category: any) => {
    const cat = await firebaseService.getDocumentsWhereValueEquals(
      "user_categories",
      "name",
      category
    );

    if (cat.length <= 0) return;

    const foundCat = cat[0];
    const res = await deleteDoc(
      doc(getFirestore(firebaseClient), "user_categories", foundCat.id)
    );
  };

  return (
    <div className="my-6">
      <h1 className="text-3xl">
        Kategorije uporabnikov{" "}
        <IconButton aria-label="add" size="small" onClick={() => setaddVisible(!addVisible)}>
          <AddIcon fontSize="inherit" />
        </IconButton>
      </h1>
      <div>
        {addVisible && (
          <div className="flex gap-[8px] my-2">
            <TextField
              value={newCatName}
              onChange={(e) => setNewCatName(e.target.value)}
              size="small"
            />
            <Button variant="contained" color="primary" onClick={addCategory}>
              Dodaj
            </Button>
          </div>
        )}
        <ul>
          {props.categories &&
            props.categories.map((category: any) => (
              <li className="my-1" key={category}>
                {category}{" "}
                <button onClick={() => deleteCategory(category)}>[-]</button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
