import { addDoc, collection, collectionGroup, CollectionReference, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import firebaseClient from "./firebaseClient";

export default {
	addDocument: async (collectionName: string, data: {}) => {
		const res = await addDoc(collection(getFirestore(firebaseClient), collectionName), data);
		return res; // todo: handle response
	},
	// call .data() on each document returned to get the data
	getCollection: async (collectionName: string) => {
		const ref: CollectionReference = await collection(getFirestore(firebaseClient), collectionName);
		const data = await getDocs(ref);

		return data;
	},
	getDocumentById: async (collectionName: string, documentId: string) => {
		const data = await getDoc(doc(getFirestore(firebaseClient), collectionName, documentId))

		return data;
	},
	getDocumentsWhereValueEquals: async (collectionName: string, field: string, value: string) => {
		const ref = await query(collectionGroup(getFirestore(firebaseClient), collectionName), where(field, '==', value));
		const data = await getDocs(ref);

		return data.docs;
	},
	updateDocument: async (collectionName: string, documentId: string, data: any) => {
		const found = doc(getFirestore(firebaseClient), collectionName, documentId);
		const res = await updateDoc(found, {...data});

		return res;
	},
};
