import {
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import firebaseService from "../../services/firebaseService";
import firebaseClient from "../../services/firebaseClient";
import { Dogodek } from ".";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  event: Dogodek;
  closeDialog: () => void;
}

export default function NotificationComponent(props: Props) {
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");

  const [selectedUsers, setSelectedUsers] = useState<any>({});
  const selectedUsersList: any[] = useMemo(() => {
    let list: any = [];
    Object.keys(selectedUsers).forEach((key: string) => {
      list = [...list, ...selectedUsers[key]];
    });
    return list;
  }, [selectedUsers]);

  const [profiles] = useCollection(
    collection(getFirestore(firebaseClient), "profiles")
  );
  const [availabilities] = useCollection(
    collection(getFirestore(firebaseClient), "availability")
  );

  const [eventResponses] = useCollection(
    collection(getFirestore(firebaseClient), "event_responses")
  );
  
  const [userCategories] = useCollection(
    collection(getFirestore(firebaseClient), "user_categories")
  );

  // users that have availability for this event + are active
  const filteredUsers = useMemo(() => {
    let filtered: any[] = [];
    profiles?.docs?.forEach((profileData) => {
      const profile = profileData.data();

      // check if user is  available
      let userIsAvailable = false;
      availabilities?.docs.forEach((availability) => {
        if (availability.data().user_id == profile.user_id) {
          if (props.event!.start_timestamp!.toDate() >= availability.data().from.toDate() && props.event!.end_timestamp!.toDate() <= availability.data().to.toDate()) {
            userIsAvailable = true;
          }
        }
      });

      let userAlreadyResponded = false;
      eventResponses?.docs.forEach((response) => {
        if (response.data().user_id == profileData.id && response.data().event_id == props.event.id) {
          userAlreadyResponded = true;
        }
      });

      if (userIsAvailable && profile.active && !userAlreadyResponded) {
        filtered.push(profileData);
      }
    });

    return filtered;
  }, [profiles, availabilities]);

  const handleSendClick = async () => {
    await firebaseService.updateDocument("events", props.event.id!, {
      num_sent_notifications: (props.event.num_sent_notifications ?? 0) + 1,
    });

    await sendNotification(props.event.id!, title, body);

    props.closeDialog();
  };

  const sendNotification = async (eventId: string, title: string, body: string) => {
    if (title.length == 0 || body.length == 0 || selectedUsersList.length == 0) return alert('Izpolnite vsa polja, in izberite vsaj enega uporabnika')
    const fcms = selectedUsersList.map((user: any) => user.data().fcm ?? '');
    if (fcms.length <= 0) return alert('Noben uporabnik nima nastavljenega FCM tokena');

    const data = {id: eventId, title: title, message: body, fcm_tokens: fcms};

    const res = await fetch(
      "https://us-central1-as-medic.cloudfunctions.net/sendEventNotification",
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    );
  };

  return (
    <div>
      <DialogTitle>Obvestilo za dogodek: {props?.event?.name ?? '/'}</DialogTitle>

      <DialogContent>
        <h2 className="text-lg mb-1 font-bold">Izbira uporabnikov</h2>
        {userCategories?.docs?.map((category) => (
          <div className="mt-2" key={category.id}>
            <p>{category.data().name}</p>
            <Autocomplete
              multiple
              options={filteredUsers.filter(usr => usr.data().category == category.data().name)}
              disableCloseOnSelect
              getOptionLabel={(option) => option.data().name + " (" + option.data().phone_number + ")"}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.data().name} ({option.data().phone_number})
                </li>
              )}
              style={{ width: 500, marginTop: 4 }}
              renderInput={(params) => <TextField {...params} label="Uporabniki" />}
              onChange={(e, selected) => setSelectedUsers({...selectedUsers, [category.data().name]: selected})}
            />
          </div>
        ))}

        <h2 className="text-lg mt-5 font-bold">Vsebina</h2>
        <div className="py-4 flex flex-col gap-4">
          <TextField
            id="outlined-multiline-static"
            label="Naslov"
            fullWidth
            rows={4}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            id="outlined-multiline-static"
            label="Vsebina"
            multiline
            fullWidth
            rows={4}
            onChange={(e) => setBody(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSendClick}>Pošlji</Button>
      </DialogActions>
    </div>
  );
}

export interface EventResponse {
  id?: string;
  user_id?: string;
  event_id: string;
  user_response?: any;
}
