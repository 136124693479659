import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAGGeWgxcYa72erKj1Nn0U-Xe1nBueswZU",
  authDomain: "as-medic.firebaseapp.com",
  projectId: "as-medic",
  storageBucket: "as-medic.appspot.com",
  messagingSenderId: "303530325528",
  appId: "1:303530325528:web:ae05fc2a0d1f37a41db56d",
  measurementId: "G-WW1GGG12DY"
};
export default initializeApp(firebaseConfig);