// get translated day name from Date object
export function getDayName(date: Date): string {
    const dayNames = [
    'Nedelja',
    'Ponedeljek',
    'Torek',
    'Sreda',
    'Četrtek',
    'Petek',
    'Sobota',
    ]


  const day = date.getDay();
  return dayNames[day];
}