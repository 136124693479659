import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Vozilo } from ".";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

interface Props {
	mode: "add" | "edit";
	onSave: ({}: any) => void;
	editingObject?: Vozilo | null;
}

export default function AddEditComponent(props: Props) {
	const [object, setObject] = useState({} as Vozilo);

	useEffect(() => {
		setObject(props.editingObject ?? ({} as Vozilo));
		window.scrollTo(0, 0);
	}, [props.editingObject]);

	const handleSave = () => {
		props.onSave(object);
	};

	const handleCancel = () => {
		props.onSave(null);
	};

	return (
		<div className="mt-4 mb-8 p-4 shadow-md bg-gray-50 border-gray-200 border">
			<h2>{props.mode == "add" ? "Dodaj" : "Uredi"} vozilo</h2>
			<div className="form flex flex-col gap-4 my-4">
				<TextField label="Znamka" type="text" value={object.brand ?? ""} onChange={(e) => setObject({ ...object, brand: e.target.value })} />
				<TextField
					label="Registrska tablica"
					type="text"
					value={object.licence_plate ?? ""}
					onChange={(e) => setObject({ ...object, licence_plate: e.target.value })}
				/>
			</div>

			<div className="flex flex-row gap-4">
				<Button variant="contained" onClick={handleSave}>
					Shrani
				</Button>
				<Button variant="contained" onClick={handleCancel}>
					Prekliči
				</Button>
			</div>
		</div>
	);
}
